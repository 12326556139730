<!--统计下级代理商的用户某个时间段切割数量-->
<template>
    <viewTable
        ref="viewTableRef"
    />
</template>

<script>
import viewTable from "../AgentStatistics/template/viewTable";
export default {
    name: "cut",
    components:{
        viewTable
    },
    data(){
        return {
            lang:this.$lang.getLang('userStatistics','cut'),
        }
    },
    methods:{

    },
    mounted() {
        // this.$refs.viewTableRef.explain = '统计自己旗下用户的某个时间段切割排行';
        this.$refs.viewTableRef.explain = this.lang['explain'];
        //[默认]设置成不统计下级
        this.$refs.viewTableRef.isType = false;
        this.$refs.viewTableRef.postData.type = 2;
        this.$refs.viewTableRef.init('UserReport.report/getUserCutReport');
    }
}
</script>

<style scoped>

</style>